import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_DEVICES_SUCCESS,
	CLEAN_REDUCER,
	FETCH_DEVICES,
	FETCH_DEVICES_COUNT,
	FETCH_DRIVER_INFO,
	FETCH_DRIVERS_INFORMATION,
	FETCH_EXTRA_VEHICLE_INFO,
	FETCH_ICONS,
	FETCH_TIMEZONES,
	FETCH_VEHICLE_INFO,
	FETCH_VEHICLES_INFORMATION,
} from './actionTypes';

const initState = {
	devices: [],
	count: 0,
	timeZones: [],
	icons: [],
	vehicleInfo: {},
	driverInfo: {},
	extraVehicleInfo: [],
	vehiclesInformation: [],
	driversInformation: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_DEVICES_SUCCESS:
			return {
				...state,
				devices: action.devices,
				count: action.count,
			};
		case FETCH_DEVICES:
			return {
				...state,
				devices: action.devices,
			};
		case FETCH_DEVICES_COUNT:
			return {
				...state,
				count: action.count,
			};
		case FETCH_ICONS:
			return {
				...state,
				icons: action.icons,
			};
		case FETCH_TIMEZONES:
			return {
				...state,
				timeZones: action.timeZones,
			};
		case FETCH_VEHICLE_INFO:
			return {
				...state,
				vehicleInfo: action.vehicleInfo,
			};
		case FETCH_DRIVER_INFO:
			return {
				...state,
				driverInfo: action.driverInfo,
			};
		case FETCH_EXTRA_VEHICLE_INFO:
			return {
				...state,
				extraVehicleInfo: action.extraVehicleInfo,
			};
		case FETCH_VEHICLES_INFORMATION:
			return {
				...state,
				vehiclesInformation: action.vehiclesInformation,
			};
		case FETCH_DRIVERS_INFORMATION:
			return {
				...state,
				driversInformation: action.driversInformation,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
