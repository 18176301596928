import React from 'react';

const PrivacyPolicies = () => {
	return (
		<div style={{ padding: '80px' }}>
			<article className="markdown-body entry-content container-lg" itemProp="text">
				<div className="markdown-heading" dir="auto" style={{ textAlign: 'center', paddingBottom: '40px' }}>
					<h1 tabIndex={-1} className="heading-element" dir="auto">
						Optimus Routes
					</h1>
				</div>
				<div className="markdown-heading" dir="auto">
					<h2 tabIndex={-1} className="heading-element" dir="auto">
						Privacy Policy for Optimus Routes App
					</h2>
				</div>
				<p dir="auto">
					Optimus GPS Tracking Corp built the Optimus Routes app as a Commercial app. The Optimus Routes App
					can be found in the{' '}
					<a href="https://apps.apple.com/app/id6443907194" rel="nofollow">
						Apple App Store
					</a>{' '}
					and the{' '}
					<a href="https://play.google.com/store/apps/details?id=com.optimus.drivers" rel="nofollow">
						Google Playstore
					</a>
					. This SERVICE is provided by Optimus GPS Tracking Corp and is intended for use as is.
				</p>
				<p dir="auto">
					This page is used to inform visitors regarding our policies with the collection, use, and disclosure
					of Personal Information if anyone decided to use our Service.
				</p>
				<p dir="auto">
					If you choose to use our Service, then you agree to the collection and use of information in
					relation to this policy. The Personal Information that we collect is used for providing and
					improving the Service. We will not use or share your information with anyone except as described in
					this Privacy Policy.
				</p>
				<p dir="auto">
					The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which
					are accessible at Optimus Routes unless otherwise defined in this Privacy Policy.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Information Collection and Use
					</h3>
				</div>
				<p dir="auto">
					For a better experience, while using our Service, we may require you to provide us with certain
					personally identifiable information, including but not limited to Access fine location, Push
					Notification, Background location, User activity fitness. The information that we request will be
					retained by us and used as described in this privacy policy.
				</p>
				<p dir="auto">
					The app does use third-party services that may collect information used to identify you.
				</p>
				<p dir="auto">Link to the privacy policy of third-party service providers used by the app</p>
				<ul dir="auto">
					<li>
						<a href="https://www.google.com/policies/privacy/" rel="nofollow">
							Google Play Services
						</a>
					</li>
					<li>
						<a href="https://sentry.io/privacy/" rel="nofollow">
							Sentry
						</a>
					</li>
				</ul>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						How long do we retain information?
					</h3>
				</div>
				<p dir="auto">
					We retain personal information for the length of time necessary to fulfill the purposes outlined in
					this Privacy Policy, unless a different retention period is requested by you or required or
					permitted by law. For example, we will retain your personal information for as long as it is needed
					to provide you with the Services or fulfill a legal or contractual obligation. Generally, we retain
					tracking history for one year, subject to exceptions and applicable laws. We may also aggregate or
					deidentify information for use in analytics, such as to analyze trends over periods of time without
					specifically identifying you. We use the following criteria to determine how long we retain personal
					information: (a) our relationship with you, such as if there is an open contract or account, or a
					pending transaction or request; (b) legal obligations to retain personal information for certain
					purposes, such as to maintain transaction records; and (c) other obligations or considerations
					relating to the retention of data, such as contract requirements, litigation holds, investigations,
					or statutes of limitation.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						How do we protect and transfer information?
					</h3>
				</div>
				<p dir="auto">
					We take efforts to employ technical, administrative, and physical security measures for personal
					information, taking into account reasonable security procedures and accessible technology. However,
					no system can be completely secure; and we cannot promise, and you should not expect, that your
					personal information will always remain secure. Your provision of personal information is at your
					own risk. The safety and security of your information also depends on you. Take steps to safeguard
					your passwords and other data and notify us as soon as possible if you believe your account security
					has been breached.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Do we sell information?
					</h3>
				</div>
				<p dir="auto">
					We do not sell personal information for any monetary consideration. We may participate in targeted
					ads, also called online behavioral advertising, using cookies and technologies. Under some privacy
					legislation, this may constitute “selling” of personal information, such as for business purposes
					(e.g., providing our Services) and commercial purposes (e.g., other marketing). To opt out of these
					practices, contact us as outlined below or see our{' '}
					<a href="https://optimustracker.com/pages/ccpa-opt-out" rel="nofollow">
						Do Not Sell or Share My Personal Information
					</a>{' '}
					link, or equivalent, if applicable.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						What are your privacy rights?
					</h3>
				</div>
				<p dir="auto">
					We value consumer privacy rights and strive to provide flexibility with how your personal data is
					used and shared. If you want to make a privacy request or have any questions, please contact us
					through our website or email us at{' '}
					<a href="mailto:legal@optimustracker.com">legal@optimustracker.com</a> with your name and email
					address, or other identifier used in connection with our Services so that we can verify your
					request. If an authorized agent is making a request on your behalf, the agent should provide its
					name and contact information, such as an email address, in addition to your information. There may
					be situations where we cannot grant your request; for example, if you make a request and we cannot
					verify your identity, or if you request deletion of data that we have a legal obligation to keep.
					Where we deny your request in whole or in part, we will take steps to inform you of the denial and
					provide an explanation of our actions and the reasons for the denial. Applicable privacy laws have
					different requirements and depend on various factors, such as where you live and how much revenue or
					data is at issue. Generally, we adhere to the following set of privacy rights to the extent
					applicable and subject to any limitations authorized by law.
				</p>
				<ul dir="auto">
					<li>
						You can access and obtain your data and ask us for certain information, including: the
						categories of personal information collected and used; the categories of the sources of data;
						the business or commercial purposes for collecting, selling, or sharing data; the categories of
						third parties to whom data is disclosed; and the specific pieces of personal information
						collected. You also have a similar right to data portability (the ability to export, back up,
						and transfer data).
					</li>
					<li>
						<strong>Amend</strong>. You can amend, correct, or rectify your data if it is inaccurate.
					</li>
					<li>
						<strong>Delete</strong>. You can have your data deleted subject to certain legal limitations.
					</li>
					<li>You can limit the processing of your data, in particular any sensitive data.</li>
					<li>
						<strong>Opt-Out</strong>. You have the right to opt-out of certain data practices. For example,
						you can unsubscribe from marketing communications by following the opt-out instructions in each
						message or by contacting us as outlined in this Privacy Policy (please note that we may still
						send non-marketing messages, and that consent to receiving marketing communications is not
						mandatory to use the Service). You may also opt out of the “sale” or “sharing” or your personal
						information to the extent applicable. Please see our Do Not Sell or Share My Personal
						Information link. You may opt out of the use of automated decision-making technology if
						applicable.
					</li>
					<li>
						You have the right to make certain complaints, including for privacy concerns. We value your
						feedback and seek the opportunity to work with you on any issues. You have the right to no
						discrimination for asserting your privacy rights.
					</li>
					<li>
						<strong>Specific State Laws</strong>. Several states have enacted privacy laws that may apply to
						you, depending on the circumstances. For example, the California Consumer Privacy Act (“CCPA”),
						amended by the California Privacy Rights Act (“CPRA”), governs certain California-related
						conduct. Under California’s “Shine the Light” law, California residents may also request certain
						information regarding our sharing personal information with third parties for their direct
						marketing purposes. Further, if you are a California resident under the age of 18, California
						Business &amp; Professions Code Section 22581 permits you to request and obtain removal of
						content you have publicly posted (but does not ensure complete removal of public content).
					</li>
					<li>
						<strong>Other Laws</strong>. Depending on where you live, you may have the right to access your
						own information that we hold; to ask that your information be corrected, updated, or erased; and
						the right to object to, or request that we restrict, certain processing of your information. Our
						legal basis for collecting and using your personal data is your consent, the fulfillment of our
						obligations pursuant to the contract created between you and us, or where the collection and use
						is in our legitimate interests and does not violate your data protection interests or
						fundamental rights. You may withdraw your consent to our collection and use of your personal
						data. Withdrawing your consent will not affect the lawfulness of any processing we conducted
						prior to your withdrawal, nor will it affect processing of your information used in reliance on
						lawful processing grounds other than consent. If certain other privacy laws apply to you that
						provide you with additional rights, please contact us to make a request.
					</li>
				</ul>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						When do we collect sensitive information?
					</h3>
				</div>
				<p dir="auto">
					If you subscribe to our GPS Tracking Platform, we collect and use your geolocation data as tracked
					with your Products. We do this to perform the services reasonably expected by the average user who
					uses these Services and to perform our service provider role in servicing accounts and processing
					and fulfilling orders. As authorized, we may also use information as reasonably necessary and
					proportionate to (a) detect security incidents, (b) resist malicious, deceptive, fraudulent, or
					illegal actions, (c) ensure physical safety of natural persons, (d) for short-term use where there
					is no disclosure or profiling, and (e) to verify or maintain the quality or safety of a service or
					device or to upgrade, enhance, or improve it.
				</p>
				<p dir="auto">
					GPS Tracking Data and Geolocation, such as real time or historic tracking of Products via our
					Platform. We collect this information when you register for and use the Services, namely our
					subscription Platform for tracking your mobile device.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Location Sharing with Your Organization
					</h3>
				</div>
				<p dir="auto">
					Within the Optimus Routes app, you may stop location sharing with your organization at any time by
					turning off location sharing in the app settings. If you turn off location sharing, we will not
					share your location with your organization.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Log Data
					</h3>
				</div>
				<p dir="auto">
					We want to inform you that whenever you use our Service, in a case of an error in the app we collect
					data and information (through third-party products) on your phone called Log Data. This Log Data may
					include information such as your device Internet Protocol (“IP”) address, device name, operating
					system version, the configuration of the app when utilizing our Service, the time and date of your
					use of the Service, and other statistics.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						How do we use cookies and similar technologies?
					</h3>
				</div>
				<p dir="auto">
					As with many companies, we may use cookies, pixels, gifs, web beacons, log files, and/or similar
					technologies to automatically collect certain information when you use the Services or interact with
					our digital media content, such as when you visit our website. This allows us to compile aggregate
					data about website traffic and interaction and to customize better experiences. Specifically, we use
					cookies to help remember and process items in the shopping cart and to save user preferences, such
					as preferred language.
				</p>
				<p dir="auto">
					For example, when you use our website, your browser may automatically send certain device, browser,
					internet connection, and general geolocation information and certain internet activity information.
					There are different types of first-party and third-party cookies, which may be placed by us or third
					parties, including essential cookies that are required to display and provide the Services;
					preference cookies that are used to store preferences and improve user experiences; analytics
					cookies to determine how the Services are used and that often collect non-personalized information;
					and marketing cookies to deliver personalized and targeted advertisements that may be of interest to
					you and others, and which are used for business and commercial marketing purposes.
				</p>
				<p dir="auto">
					In particular, when you visit our website, we may use services of, and your device data may be
					shared with, Google, Meta fka Facebook, and Shopify. For more information, see e.g., Meta’s Privacy
					Policy at{' '}
					<a href="https://www.facebook.com/privacy/policy/" rel="nofollow">
						https://www.facebook.com/privacy/policy/
					</a>{' '}
					and Shopify’s Privacy Policy at{' '}
					<a href="https://www.shopify.com/legal/privacy" rel="nofollow">
						https://www.shopify.com/legal/privacy
					</a>
					. We use third-party advertising companies to display ads tailored to your individual interests
					based on your website activity and to provide related services such as analytics and research. Third
					parties, in turn, may use a cookie, web beacon, or other similar technology to collect information.
					See All about Ads at{' '}
					<a href="http://www.aboutads.info/choices" rel="nofollow">
						www.aboutads.info/choices
					</a>
					. For example, we use Google cookies and tools, such as Google Analytics. For more information,
					visit the Google Cookie Policy at{' '}
					<a href="https://policies.google.com/technologies/cookies?hl=en-US" rel="nofollow">
						https://policies.google.com/technologies/cookies?hl=en-US
					</a>
					, Google Analytics Opt-Out Browser Add-On at{' '}
					<a href="https://tools.google.com/dlpage/gaoptout" rel="nofollow">
						https://tools.google.com/dlpage/gaoptout
					</a>
					, Ads Settings at{' '}
					<a href="https://adssettings.google.com/authenticated" rel="nofollow">
						https://adssettings.google.com/authenticated
					</a>
					, and Google's Advertising Principles at{' '}
					<a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en" rel="nofollow">
						https://support.google.com/adwordspolicy/answer/1316548?hl=en
					</a>
					. You can change your cookie settings in your internet browser and use device settings to manage
					your privacy controls. See also All About Cookies at{' '}
					<a href="https://www.allaboutcookies.org/" rel="nofollow">
						https://www.allaboutcookies.org/
					</a>
					. When you use our GPS Tracking Platform via our app, we do not use Google or Meta marketing
					cookies.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Service Providers
					</h3>
				</div>
				<p dir="auto">We may employ third-party companies and individuals due to the following reasons:</p>
				<ul dir="auto">
					<li>To facilitate our Service;</li>
					<li>To provide the Service on our behalf;</li>
					<li>To perform Service-related services; or</li>
					<li>To assist us in analyzing how our Service is used.</li>
				</ul>
				<p dir="auto">
					We want to inform users of this Service that these third parties have access to their Personal
					Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
					obligated not to disclose or use the information for any other purpose.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Security
					</h3>
				</div>
				<p dir="auto">
					We value your trust in providing us your Personal Information, thus we are striving to use
					commercially acceptable means of protecting it. But remember that no method of transmission over the
					internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its
					absolute security.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Links to Other Sites
					</h3>
				</div>
				<p dir="auto">
					This Service may contain links to other sites. If you click on a third-party link, you will be
					directed to that site. Note that these external sites are not operated by us. Therefore, we strongly
					advise you to review the Privacy Policy of these websites. We have no control over and assume no
					responsibility for the content, privacy policies, or practices of any third-party sites or services.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Do we collect information about children?
					</h3>
				</div>
				<p dir="auto">
					No, our Services are not intended for use with children, and we do not knowingly collect any
					personal information about children under the age of 13 without the verifiable consent and request
					of the parent or guardian. If we obtain actual knowledge that we have collected such information, we
					will delete it from our database. We have no such information to use or to disclose to third parties
					or to otherwise report, including under the Children’s Online Privacy Protection Act (“COPPA”). We
					do not have actual knowledge of selling or sharing the personal information of consumers under the
					age of 16.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Changes to This Privacy Policy
					</h3>
				</div>
				<p dir="auto">
					We may update our Privacy Policy from time to time. Thus, you are advised to review this page
					periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on
					this page.
				</p>
				<p dir="auto">
					This policy is effective as of <strong>2023-10-19</strong>
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Contact Us
					</h3>
				</div>
				<p dir="auto">
					If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at{' '}
					<a href="mailto:support@optimustracker.com">support@optimustracker.com</a>.
				</p>
				<p dir="auto">
					This privacy policy page was created at{' '}
					<a href="https://privacypolicytemplate.net" rel="nofollow">
						privacypolicytemplate.net
					</a>
					and modified/generated by{' '}
					<a href="https://app-privacy-policy-generator.nisrulz.com/" rel="nofollow">
						App Privacy Policy Generator
					</a>
				</p>
				<hr />
				<div className="markdown-heading" dir="auto">
					<h2 tabIndex={-1} className="heading-element" dir="auto">
						Terms &amp; Conditions
					</h2>
				</div>
				<p dir="auto">
					By downloading or using the app, these terms will automatically apply to you – you should make sure
					therefore that you read them carefully before using the app. You’re not allowed to copy or modify
					the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract
					the source code of the app, and you also shouldn’t try to translate the app into other languages or
					make derivative versions. The app itself, and all the trademarks, copyright, database rights, and
					other intellectual property rights related to it, still belong to Optimus GPS Tracking Corp.
				</p>
				<p dir="auto">
					Optimus GPS Tracking Corp is committed to ensuring that the app is as useful and efficient as
					possible. For that reason, we reserve the right to make changes to the app or to charge for its
					services, at any time and for any reason. We will never charge you for the app or its services
					without making it very clear to you exactly what you’re paying for.
				</p>
				<p dir="auto">
					The Optimus Routes app stores and processes personal data that you have provided to us, to provide
					our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore
					recommend that you do not jailbreak or root your phone, which is the process of removing software
					restrictions and limitations imposed by the official operating system of your device. It could make
					your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security
					features and it could mean that the Optimus Routes app won’t work properly or at all.
				</p>
				<p dir="auto">The app does use third-party services that declare their Terms and Conditions.</p>
				<p dir="auto">Link to Terms and Conditions of third-party service providers used by the app</p>
				<ul dir="auto">
					<li>
						<a href="https://policies.google.com/terms" rel="nofollow">
							Google Play Services
						</a>
					</li>
					<li>
						<a href="https://sentry.io/terms/" rel="nofollow">
							Sentry
						</a>
					</li>
				</ul>
				<p dir="auto">
					You should be aware that there are certain things that Optimus GPS Tracking Corp will not take
					responsibility for. Certain functions of the app will require the app to have an active internet
					connection. The connection can be Wi-Fi or provided by your mobile network provider, but Optimus GPS
					Tracking Corp cannot take responsibility for the app not working at full functionality if you don’t
					have access to Wi-Fi, and you don’t have any of your data allowance left.
				</p>
				<p dir="auto">
					If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the
					agreement with your mobile network provider will still apply. As a result, you may be charged by
					your mobile provider for the cost of data for the duration of the connection while accessing the
					app, or other third-party charges. In using the app, you’re accepting responsibility for any such
					charges, including roaming data charges if you use the app outside of your home territory (i.e.
					region or country) without turning off data roaming. If you are not the bill payer for the device on
					which you’re using the app, please be aware that we assume that you have received permission from
					the bill payer for using the app.
				</p>
				<p dir="auto">
					Along the same lines, Optimus GPS Tracking Corp cannot always take responsibility for the way you
					use the app i.e. You need to make sure that your device stays charged – if it runs out of battery
					and you can’t turn it on to avail the Service, Optimus GPS Tracking Corp cannot accept
					responsibility.
				</p>
				<p dir="auto">
					With respect to Optimus GPS Tracking Corp’s responsibility for your use of the app, when you’re
					using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated
					and correct at all times, we do rely on third parties to provide information to us so that we can
					make it available to you. Optimus GPS Tracking Corp accepts no liability for any loss, direct or
					indirect, you experience as a result of relying wholly on this functionality of the app.
				</p>
				<p dir="auto">
					At some point, we may wish to update the app. The app is currently available on Android &amp; iOS –
					the requirements for the both systems(and for any additional systems we decide to extend the
					availability of the app to) may change, and you’ll need to download the updates if you want to keep
					using the app. Optimus GPS Tracking Corp does not promise that it will always update the app so that
					it is relevant to you and/or works with the Android &amp; iOS version that you have installed on
					your device. However, you promise to always accept updates to the application when offered to you,
					We may also wish to stop providing the app, and may terminate use of it at any time without giving
					notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and
					licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed)
					delete it from your device.
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Changes to This Terms and Conditions
					</h3>
				</div>
				<p dir="auto">
					We may update our Terms and Conditions from time to time. Thus, you are advised to review this page
					periodically for any changes. We will notify you of any changes by posting the new Terms and
					Conditions on this page.
				</p>
				<p dir="auto">
					These terms and conditions are effective as of <strong>2023-10-19</strong>
				</p>
				<div className="markdown-heading" dir="auto">
					<h3 tabIndex={-1} className="heading-element" dir="auto">
						Contact Us
					</h3>
				</div>
				<p dir="auto">
					If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact
					us at <a href="mailto:support@optimustracker.com">support@optimustracker.com</a>.
				</p>
				<p dir="auto">
					This Terms and Conditions page was generated by{' '}
					<a href="https://app-privacy-policy-generator.nisrulz.com/" rel="nofollow">
						App Privacy Policy Generator
					</a>
				</p>
			</article>
		</div>
	);
};

export default PrivacyPolicies;
