// Language
export const SET_LANGUAGE = 'SET_LANGUAGE';

//Others
export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const FETCH_USER_CLIENT = 'FETCH_USER_CLIENT';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';

// Account
export const OPEN_ADD_MODAL_ACCOUNT = 'OPEN_ADD_MODAL_ACCOUNT';
export const CLOSE_ADD_MODAL_ACCOUNT = 'CLOSE_ADD_MODAL_ACCOUNT';
export const OPEN_EDIT_MODAL_ACCOUNT = 'OPEN_EDIT_MODAL_ACCOUNT';
export const CLOSE_EDIT_MODAL_ACCOUNT = 'CLOSE_EDIT_MODAL_ACCOUNT';
export const END_MODAL_REQUEST_ACCOUNT = 'END_MODAL_REQUEST_ACCOUNT';
export const INIT_REQUEST_ACCOUNT = 'INIT_REQUEST_ACCOUNT';
export const END_REQUEST_ACCOUNT = 'END_REQUEST_ACCOUNT';
export const EDIT_LANGUAGE_SUCCESS = 'EDIT_LANGUAGE_SUCCESS';

// Devices
export const OPEN_ADD_MODAL_DEVICES = 'OPEN_ADD_MODAL_DEVICES';
export const CLOSE_ADD_MODAL_DEVICES = 'CLOSE_ADD_MODAL_DEVICES';
export const OPEN_EDIT_MODAL_DEVICES = 'OPEN_EDIT_MODAL_DEVICES';
export const CLOSE_EDIT_MODAL_DEVICES = 'CLOSE_EDIT_MODAL_DEVICES';
export const INIT_MODAL_REQUEST_DEVICES = 'INIT_MODAL_REQUEST_DEVICES';
export const END_MODAL_REQUEST_DEVICES = 'END_MODAL_REQUEST_DEVICES';
export const INIT_REQUEST_DEVICES = 'INIT_REQUEST_DEVICES';
export const END_REQUEST_DEVICES = 'END_REQUEST_DEVICES';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICES_COUNT = 'FETCH_DEVICES_COUNT';
export const ADD_DEVICES_SUCCESS = 'ADD_DEVICES_SUCCESS';
export const DELETE_DEVICES_SUCCESS = 'DELETE_DEVICES_SUCCESS';
export const DISABLE_DEVICES_SUCCESS = 'DISABLE_DEVICES_SUCCESS';
export const EDIT_DEVICES_SUCCESS = 'EDIT_DEVICES_SUCCESS';

// Device Models
export const FETCH_DEVICEMODEL = 'FETCH_DEVICEMODEL';

// Icons
export const FETCH_ICONS = 'FETCH_ICONS';

// Map
export const FETCH_MAP_DEVICES = 'FETCH_MAP_DEVICES';
export const FETCH_SELECTED_DEVICE = 'FETCH_SELECTED_DEVICE';
export const TOGGLE_REFRESH = 'TOGGLE_REFRESH';
export const TOGGLE_OTHER_UNITS = 'TOGGLE_OTHER_UNITS';
export const FETCH_TRACE_BY_ID = 'FETCH_TRACE_BY_ID';
export const INIT_FETCH_MAP_DEVICES = 'INIT_FETCH_MAP_DEVICES';
export const GOOGLE_LOADED = 'GOOGLE_LOADED';
export const UPDATE_DEVICE_EVENT = 'UPDATE_DEVICE_EVENT';
export const INIT_MAP_CONFIGURATION = 'INIT_MAP_CONFIGURATION';
export const END_FETCH_MAP_DEVICES = 'END_FETCH_MAP_DEVICES';
export const LOADING_TRACE = 'LOADING_TRACE';
export const FETCH_DEVICE_COMMANDS = 'FETCH_DEVICE_COMMANDS';
export const CHANGE_TRACE_COLOR_BY_SPEED = 'CHANGE_TRACE_COLOR_BY_SPEED';
export const FETCH_SPEED_RANGES_CONFIGURATION = 'FETCH_SPEED_RANGES_CONFIGURATION';

// CONFIGURATION
export const START_LOADING_CONFIGURATION = 'START_LOADING_CONFIGURATION';
export const STOP_LOADING_CONFIGURATION = 'STOP_LOADING_CONFIGURATION';
export const SHOW_CONFIGURATION = 'SHOW_CONFIGURATION';
export const CLOSE_CONFIGURATION = 'CLOSE_CONFIGURATION';
export const CHANGE_MOVEMENT_COLOR = 'CHANGE_MOVEMENT_COLOR';
export const CHANGE_PARKING_COLOR = 'CHANGE_PARKING_COLOR';
export const CHANGE_IDLE_COLOR = 'CHANGE_IDLE_COLOR';
export const CHANGE_TRACE_COLOR = 'CHANGE_TRACE_COLOR';
export const CHANGE_MAP_TYPE = 'CHANGE_MAP_TYPE';
export const CHANGE_CENTER_SELECT = 'CHANGE_CENTER_SELECT';
export const CHANGE_CENTER_UPDATE = 'CHANGE_CENTER_UPDATE';
export const CHANGE_SHOW_TRACE = 'CHANGE_SHOW_TRACE';
export const CHANGE_TRAFIC = 'CHANGE_TRAFIC';
export const CHANGE_MUTE_NOTIFICATION = 'CHANGE_MUTE_NOTIFICATION';
export const RESTORE_CONFIGURATION = 'RESTORE_CONFIGURATION';
export const CHANGE_CLUSTER_DEVICES = 'CHANGE_CLUSTER_DEVICES';

// Rabbit
export const RABBIT_DISCONECTED = 'RABBIT_DISCONECTED';
export const RABBIT_CONECTED = 'RABBIT_CONECTED';
export const RABBIT_MESSAGE = 'RABBIT_MESSAGE';
export const RABBIT_MAIN_MESSAGE = 'RABBIT_MAIN_MESSAGE';

// Time Zone
export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';

// Alerts
export const FEATCH_ALERTS = 'FEATCH_ALERTS';
export const NEW_ALERT = 'NEW_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const STOP_ALERT = 'STOP_ALERT';
