export const ADD_DEVICES_SUCCESS = 'ADD_DEVICES_SUCCESS';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICES_COUNT = 'FETCH_DEVICES_COUNT';
export const DELETE_DEVICES_SUCCESS = 'DELETE_DEVICES_SUCCESS';
export const EDIT_DEVICES_SUCCESS = 'EDIT_DEVICES_SUCCESS';
export const FETCH_ICONS = 'FETCH_ICONS';
export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const FETCH_VEHICLE_INFO = 'FETCH_VEHICLE_INFO';
export const FETCH_DRIVER_INFO = 'FETCH_DRIVER_INFO';
export const FETCH_EXTRA_VEHICLE_INFO = 'FETCH_EXTRA_VEHICLE_INFO';
export const FETCH_VEHICLES_INFORMATION = 'FETCH_VEHICLES_INFORMATION';
export const FETCH_DRIVERS_INFORMATION = 'FETCH_DRIVERS_INFORMATION';
export const UPLOAD_DEVICES = 'upload-devices';
