import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import AlertService from '../alertConfiguration/alerts.service';
import { FETCH_MODELS_PROFILES } from '../modelProfile/actionTypes';
import { FETCH_DEVICE_MODELS } from '../models/actionTypes';
import SimService from '../sims/sim.service';
import unitSystem from '../unitSystems';
import { ADD_DEVICES_SUCCESS, FETCH_DEVICES, FETCH_DEVICES_COUNT, FETCH_ICONS, FETCH_TIMEZONES } from './actionTypes';

const handeldUploadFile = (stateDevices, count, newDevices, editedDevices) => {
	let newCount = count + newDevices.length;
	let newDevicesCopy;

	if (newDevices.length > 15) {
		newDevicesCopy = _.cloneDeepWith(newDevices);
		newDevicesCopy.splice(0, newDevicesCopy.length - 15);
	} else {
		newDevicesCopy = _.cloneDeepWith(stateDevices);
		newDevicesCopy.unshift(...newDevices);
		if (newDevicesCopy.length > 15) newDevicesCopy.length = 15;
	}

	let allDevices = newDevicesCopy.map((stateDev) => {
		let hasUpdate = editedDevices.find((updated) => updated.pin === stateDev.pin);
		return hasUpdate ? { ...stateDev, ...hasUpdate } : stateDev;
	});
	return { devices: allDevices, count: newCount };
};

export const HandleUploadFile = (values) => (dispatch, getState) => {
	let state = getState();
	let stateDevices = state.device.devices;
	let count = state.device.count;
	let data = JSON.stringify(values);
	const formData = new FormData();

	formData.append('file', values.file);
	formData.set('formData', data);

	return axios
		.post(`/Devices/UploadDevicesFile`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then((response) => {
			let uploadResponse = response.data;
			let devicesResponse = handeldUploadFile(stateDevices, count, uploadResponse.added, uploadResponse.edited);
			dispatch({ type: ADD_DEVICES_SUCCESS, ...devicesResponse });

			return uploadResponse;
		});
};

export const exportExcel = (url, params, name) => {
	return axios({
		url: '/Devices/' + url,
		method: 'POST',
		responseType: 'blob',
		data: params,
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}-${moment().format(unitSystem.getDateTimeFormat())}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const DownloadDevices = (devices) => () => {
	let params = { devices };
	return exportExcel('ExportDevices', params, 'Units');
};

export const DownloadDevicesTemplate = () => () => {
	let params = {};
	return exportExcel('ExportDevicesTemplate', params, 'DevicesTemplate');
};

export const DownloadNotAdded = (sims) => () => {
	let params = { sims: sims };
	return exportExcel('ExportNotAddedDevices', params, 'NotAddedDevices');
};

export const GetClientDevices = (clientId) => () => {
	return axios.get(`/clients/${clientId}/devices`).then((response) => response.data);
};

export const GetIcons = async (dispatch, cancelTocken) => {
	try {
		const response = await axios.get(
			`/Icons?filter=${encodeURIComponent(JSON.stringify({ where: { type: 1 } }))}`,
			cancelTocken
		);
		dispatch({ type: FETCH_ICONS, icons: response.data });
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const GetTimeZones = async (dispatch, cancelToken) => {
	const {
		data: { success: timezones, error },
	} = await axios.get('/v2/time-zones', { cancelToken });
	if (error) {
		throw error;
	}
	dispatch({ type: FETCH_TIMEZONES, timeZones: timezones });
	return timezones;
};

export const GetProfilesByCompany = (dispatch, companyId) => {
	return axios.get(`/CompanyProfiles/${companyId}/GetProfilesByCompany`).then((response) => {
		let deviceModels = response.data.deviceModels;
		let profiles = response.data.profiles;
		dispatch({ type: FETCH_DEVICE_MODELS, deviceModels });
		dispatch({ type: FETCH_MODELS_PROFILES, profiles });
	});
};

export const GetDevicesMetaData = (cancelTocken) => (dispatch, getState) => {
	const companyId = getState().auth.company.id;

	return [
		SimService.getSims({ dispatch }),
		GetIcons(dispatch, cancelTocken),
		GetTimeZones(dispatch, cancelTocken),
		GetProfilesByCompany(dispatch, companyId),
	];
};

export const GetDevices = (filterConfiguration) => (dispatch) => {
	const { search = '', page = 1, pageSize = 15, order = 'ascend', orderBy = '', cancelTocken } = filterConfiguration;
	const filter = {
		search: search,
		limit: pageSize,
		skip: (page - 1) * pageSize,
	};

	return axios
		.get(
			`/v2/devices?limit=${filter.limit}&skip=${filter.skip}&search=${encodeURIComponent(
				filter.search
			)}&order=${order}&orderBy=${orderBy}`,
			cancelTocken
		)
		.then((response) => {
			const { success, error } = response?.data;
			if (error) {
				dispatch({
					type: FETCH_DEVICES_COUNT,
					count: 0,
				});
				dispatch({ type: FETCH_DEVICES, devices: [] });
				return;
			}
			dispatch({
				type: FETCH_DEVICES_COUNT,
				count: success.count,
			});
			dispatch({ type: FETCH_DEVICES, devices: success.data });
		});
};

export const updateDevices = (stateDevices, count, newDevice) => {
	let stateDevicesCopy = _.cloneDeepWith(stateDevices);
	let newCount = count + 1;

	stateDevicesCopy.unshift(newDevice);
	return { devices: stateDevicesCopy, count: newCount };
};

export const AddDevice = (newDevice) => (dispatch, getState) => {
	const state = getState();
	const stateDevices = state.device.devices;
	const count = state.device.count;

	const values = {
		...newDevice,
		clientId: +newDevice.clientId,
		timeZoneId: +newDevice.timeZoneId,
		iconId: +newDevice.iconId,
		dataPort: +newDevice.dataPort,
		modelProfileId: +newDevice.modelProfileId,
	};

	return axios.post(`/v2/devices`, values).then((response) => {
		const result = response.data.success;

		if (result) {
			const deviceAdded = deviceDataSturcture(result);
			const devicesResponse = updateDevices(stateDevices, count, deviceAdded);
			dispatch({ type: ADD_DEVICES_SUCCESS, ...devicesResponse });
		}
	});
};

export const DeleteDevices = (ids, search) => (dispatch) => {
	let options = { data: { ids: ids } };

	return axios.delete(`/Devices/`, options).then((response) => {
		if (response.status === 204) return dispatch(GetDevices(search.search, search.page, search.pageSize));
	});
};

const deviceDataSturcture = (data) => {
	const {
		id,
		simId,
		pin,
		description,
		imei,
		dataPort,
		firmwareVersion,
		active,
		iconId,
		clientId,
		timeZoneId,
		modelProfileId,
		client: {
			name: clientName,
			company: { name: companyName },
		},
		modelProfile: {
			deviceModelId,
			description: modelProfileDescription,
			deviceModel: {
				description: deviceModelDescription,
				brand: { description: brandDescription, id: brandId },
			},
		},
		timeZone: { utcOffsetMinutes },
		status,
		intervalTime,
		drivingCalculationMethod,
		availableForStripeSubscriptions,
		trialDays,
		emailNotificationLimit,
		smsNotificationLimit,
		forceOptimusSubscription,
	} = data;

	const deviceUpdated = {
		id,
		simId,
		pin,
		description,
		imei,
		dataPort,
		firmwareVersion,
		active,
		iconId,
		clientId,
		timeZoneId,
		modelProfileId,
		clientName,
		companyName,
		deviceModelId,
		modelProfileDescription,
		deviceModelDescription,
		brandId,
		brandDescription,
		utcOffsetMinutes,
		status,
		intervalTime,
		drivingCalculationMethod,
		availableForStripeSubscriptions,
		trialDays,
		emailNotificationLimit,
		smsNotificationLimit,
		forceOptimusSubscription,
	};
	if (data.simId) {
		deviceUpdated.line = data.sim.line;
	}
	return deviceUpdated;
};

export const EditDevice = (newDevice) => (dispatch, getState) => {
	const state = getState();
	const stateDevices = state.device.devices;
	const count = state.device.count;
	const id = newDevice.id;

	if (newDevice.deviceModelId) {
		delete newDevice.deviceModelId;
	}

	const deviceInfo = {
		...newDevice,
		clientId: +newDevice.clientId,
		timeZoneId: +newDevice.timeZoneId,
		iconId: +newDevice.iconId,
		modelProfileId: +newDevice.modelProfileId,
		simId: newDevice.simId ? newDevice.simId : null,
	};
	return axios.patch(`/v2/devices/${id}`, deviceInfo).then((response) => {
		const result = response.data.success;

		if (result) {
			const deviceUpdated = deviceDataSturcture(result);
			const edit = stateDevices.map((device) => (device.id === id ? deviceUpdated : device));
			dispatch({ type: ADD_DEVICES_SUCCESS, devices: edit, count });
		}
	});
};

export const EditDeviceMassiveSelected = (ids, newDevice) => (dispatch, getState) => {
	let state = getState();
	let stateDevices = _.cloneDeepWith(state.device.devices);
	let devicesIds = ids;
	let newInfo = newDevice;
	let edit = undefined;
	return axios
		.patch(`/Devices/EditDevicesMassive/`, {
			Ids: devicesIds,
			options: newInfo,
		})
		.then((devices) => {
			edit = stateDevices.map((stateDevice) => {
				let dev = devices.data.find((newDevice) => newDevice.id === stateDevice.id);
				return dev ? dev : stateDevice;
			});
			dispatch({ type: FETCH_DEVICES, devices: edit });
		});
};

export const CheckPinExistance = (pin) => () => {
	return axios
		.get(`/v2/devices/count?pin=${encodeURIComponent(pin)}`)
		.then((response) => response.data.success)
		.catch(() => {
			return 0;
		});
};

export const CheckImeiExistance = (imei) => () => {
	return axios
		.get(`/v2/devices/count?imei=${imei}`)
		.then((response) => response.data.success)
		.catch(() => {
			return 0;
		});
};
export const GetDigitalInputs = (id) => () => {
	const filter = { include: 'event', where: { deviceId: id } };
	return axios
		.get(`/DigitalInputs?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => response.data);
};
export const AddDigitalInput =
	({ deviceId, eventId, description, eventIconId }) =>
	() => {
		return axios
			.post(`/DigitalInputs`, {
				deviceId,
				eventId,
				description,
				eventIconId,
			})
			.then(() => {
				AlertService.updateDigitalInput(deviceId);
			});
	};

export const DeleteDigitalInput = (deviceId, eventId) => () => {
	return axios
		.delete(`/DigitalInputs/DeleteDigitalInput`, {
			data: { deviceId, eventId },
		})
		.then(() => {
			AlertService.updateDigitalInput(deviceId);
		});
};

export const ShareDevicesWithClient = (deviceIdList, clientIdList) => () => {
	return axios.post(`SharedDevices/ShareDevicesWithClient`, {
		deviceIdList,
		clientIdList,
	});
};
export const GetClientsPerDevices = (deviceId) => () => {
	return axios.get(`/v2/devices/${deviceId}/shared-clients`).then((response) => response.data?.success);
};
export const StopSharingDeviceWithClients = (deviceId, clientIdList) => () => {
	return axios.delete(`SharedDevices/StopSharingDeviceWithClients`, {
		data: { deviceId, clientIdList },
	});
};

export const GetClientsForDevices = async (companyId) => {
	const filter = {
		where: {
			and: [{ companyId: companyId }],
		},
	};
	try {
		const response = await axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const ReplaceUnit = async (id, data) => await axios.put(`/v2/devices/${id}/replace`, { ...data });

export const ChangeStripeStatus = async (id, newStatus) =>
	await axios.patch(`/v2/devices/${id}`, { availableForStripeSubscriptions: newStatus });

export const GetStripeClients = async (clientId) => {
	const response = await axios.get('/v2/clients/stripe', { params: { clientId } });
	return response.data;
};

export const updateDevicesAction = (id) => (dispatch, getState) => {
	const state = getState();
	const stateDevices = state.device.devices;
	const count = state.device.count;
	const filter = {
		include: ['sim', { modelProfile: { deviceModel: 'brand' } }, { client: 'company' }, 'timeZone', 'deviceStatus'],
	};
	return axios.get(`/Devices/${id}/?filter=${encodeURIComponent(JSON.stringify(filter))}`).then((response) => {
		const deviceUpdated = deviceDataSturcture(response.data);
		const edit = stateDevices.map((device) => (device.id === id ? deviceUpdated : device));
		dispatch({ type: ADD_DEVICES_SUCCESS, devices: edit, count });
	});
};
