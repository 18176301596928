import axios from 'axios';
import { GroupDto } from '@/features/groups/group.dto';
import { UserDto } from '@/features/users/users.dto';
import { ApiResult, Result } from '@/OptimusRoutes/interfaces';

type Service = {
	get: () => Promise<Result<ApiResult<GroupDto[]>>>;
	create: (group: Partial<GroupDto>) => Promise<Result<GroupDto>>;
	edit: (group: Partial<GroupDto>) => Promise<Result<GroupDto>>;
	delete: (id: number) => Promise<Result>;
	getAccounts: (accountId: number) => Promise<UserDto[]>;
	unShare: (id: number) => Promise<Result>;
};

const get = async (): Promise<Result<ApiResult<GroupDto[]>>> => {
	const { data } = await axios.get<Result<ApiResult<GroupDto[]>>>(`/v2/group`);
	return data;
};

const create = async (group: Partial<GroupDto>): Promise<Result<GroupDto>> => {
	const { data } = await axios.post<Result<GroupDto>>(`/v2/group`, group);
	return data;
};
const edit = async ({ id, ...group }: Partial<GroupDto>): Promise<Result<GroupDto>> => {
	const { data } = await axios.patch<Result<GroupDto>>(`/v2/group/${id}`, group);
	return data;
};
const remove = async (id: number): Promise<Result> => {
	const { data } = await axios.delete<Result>(`/v2/group/${id}`);
	return data;
};

// To-DO Migrate to V2
const getAccounts = async (accountId: number): Promise<UserDto[]> => {
	const { data } = await axios.get<UserDto[]>(`/Companies/${accountId}/accounts`);
	return data;
};

const unShare = async (id: number): Promise<Result> => {
	const { data } = await axios.patch<Result>(`/v2/group/${id}/unShare`);
	return data;
};

const GroupsService: Service = {
	get,
	create,
	edit,
	delete: remove,
	getAccounts,
	unShare,
};

export default GroupsService;
