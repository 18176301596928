import { Grid } from 'antd';

const { useBreakpoint } = Grid;

enum Layout {
	inline = 'inline',
	vertical = 'vertical',
}

export const ReportOptionsLayout = (isExport: boolean) => {
	const screens = useBreakpoint();
	const layout: Layout = !screens.md || isExport ? Layout.vertical : Layout.inline;

	const style: React.CSSProperties = {
		rowGap: layout === Layout.inline ? 10 : 16,
		columnGap: 16,
	};

	return { layout, style };
};
